<template>
  <div>
    <div class="pageTitle">原始資料下載</div>

    <div class="borderColor mb-4">
      <div class="d-flex align-items-center flex-wrap m-2">
        <div class="mx-2 mr-4 my-2">
          案場:
          <input
            type="text"
            name="factoryNameInput"
            list="factoryList"
            v-model="searchInput"
            class="filterInput ml-2"
            placeholder="案場名稱"
            @change="handleInputChange"
          />
          <datalist id="factoryList">
            <option
              v-for="i in filteredFactoryInfo"
              :key="i.factoryId"
              :value="i.factory + '_' + i.factoryId"
            />
          </datalist>
        </div>

        <div class="ml-2 mr-4 my-2">
          日期:
          <date-picker
            class="ml-2"
            style="width: 150px"
            value-type="format"
            format="YYYY-MM-DD"
            v-model="selectDate"
            placeholder="請選擇日期"
            :disabled-date="disabledDateAfterToday"
          ></date-picker>
        </div>
      </div>

      <div class="d-flex align-items-center flex-wrap m-2">
        <div class="d-flex ml-2 mr-4 my-2">
          <span class="text-nowrap"> 選擇下載項目: </span>
          <div>
            <b-form-checkbox
              id="checkbox-selectAll"
              name="checkbox-selectAll"
              v-model="allCheckbox"
              value="selected"
              unchecked-value="unSelected"
              class="ml-3 text-nowrap"
              @change="toggleSelectAll"
              >全選
            </b-form-checkbox>
            <b-form-checkbox-group
              v-model="selected"
              :options="selectOptions"
              class="ml-3 mt-1 selectBox"
            />
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center flex-wrap m-2">
        <div class="mx-2 my-2 btnWrap">
          <button
            class="confirmButton p-1"
            :class="{ disableButton: loadingData }"
            style="width: 120px"
            @click="download"
          >
            下載
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapState, mapActions } from 'vuex'
import { rawDataDownload } from '../store/function/common/rawDataDownload'
import { disabledDateAfterToday } from '@/utils/datetime'
export default {
  name: 'RawDataDownload',
  data () {
    return {
      selectDate: DateTime.local()
        .setZone('Asia/Taipei')
        .toFormat('yyyy-MM-dd'),
      loadingData: false,
      searchInput: '',
      allCheckbox: 'unSelected',
      selected: [],
      selectOptions: []
    }
  },
  computed: {
    ...mapState(['factoryInfo']),
    filteredFactoryInfo () {
      return this.factoryInfo.filter((item) => !item.isDemoSite)
    }
  },
  watch: {
    selected () {
      this.allCheckbox =
        this.selected.length === this.selectOptions.length
          ? 'selected'
          : 'unSelected'
    }
  },
  methods: {
    ...mapActions(['asyncInfo']),
    disabledDateAfterToday,
    async download () {
      if (!this.searchInput) return this.$swal('請輸入案場')
      if (!this.selectDate) return this.$swal('請選擇日期')
      if (!this.selected?.length) return this.$swal('請選擇下載項目')

      this.loadingData = true

      const dataDownload = await rawDataDownload(
        this.searchInput,
        this.selectDate,
        this.selected
      )
      if (!dataDownload) {
        this.$swal('所選項目尚無資料')
      }
      this.loadingData = false
    },
    toggleSelectAll () {
      this.selected = this.allCheckbox === 'selected' ? this.selectOptions : []
    },
    handleInputChange () {
      if (!this.searchInput) {
        this.allCheckbox = ''
        this.selectOptions = []
        return
      }

      // clear selected options
      this.selected = []

      // check input factoryName correct
      const factoryNameList = this.factoryInfo.map(
        (item) => item.factory + '_' + item.factoryId
      )

      const inputCorrect = factoryNameList.includes(this.searchInput)
      if (!inputCorrect) {
        return this.$swal('請正確輸入案場(含編號)')
      }

      // list selectOptions
      const selectedOption = this.searchInput.split('_')[0]
      const [selectedFactoryData] = this.factoryInfo.filter(
        (item) => item.factory === selectedOption
      )

      const irrList = selectedFactoryData.irrPct?.length
        ? selectedFactoryData.irrPct.map((_, idx) => `IRR_${idx + 1}`)
        : [`IRR_1`]

      const invList = selectedFactoryData.invBrand.map(
        (_, idx) => `INV_${idx + 1}`
      )

      this.selectOptions = [
        'HV-meter',
        'LV-meter',
        ...irrList,
        'PVTemp',
        'ENVTemp',
        'Anemometer',
        ...invList
      ]
    }
  },
  async mounted () {
    if (!this.factoryInfo.length) await this.asyncInfo()
  }
}
</script>

<style lang="scss" scoped>
.btnWrap {
  width: 100%;
  text-align: center;
}
</style>
