'use strict'

const { DateTime } = require('luxon')
const { getRawDataApi } = require('../../../api')
const ExcelJS = require('exceljs')

const HV_METER = 'HV-meter'
const LV_METER = 'LV-meter'
const PVTEMP = 'PVTemp'
const ENVTEMP = 'ENVTemp'
const ANEMOMETER = 'Anemometer'
const RAWDATA_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

const rawDataDownload = async (factory, date, selectedOptions) => {
  const result = await getRawDataApi(factory, date)

  if (!result?.data?.success) return false

  const factoryName = factory.split('_')[0]
  const data = result.data.data
  const workbook = new ExcelJS.Workbook() // create excel

  const firstData = data[0]
  const invLength = firstData.detail.acp && firstData.detail.acp.length
  const baseColumn = [
    { header: '案場名稱', key: 'siteName', width: 15 },
    { header: '設備名稱', key: 'device', width: 15 },
    { header: '時間', key: 'time', width: 20 }
  ]

  const meterKeys = [
    { key: 'Freq', name: '頻率' },
    { key: 'I_a', name: 'A 相電流' },
    { key: 'I_b', name: 'B 相電流' },
    { key: 'I_c', name: 'C 相電流' },
    { key: 'KVAR_tot', name: '虛功率' },
    { key: 'KVA_tot', name: '視在功率' },
    { key: 'P', name: '平均功率' },
    { key: 'Vll_ab', name: 'AB 相電壓' },
    { key: 'Vll_bc', name: 'BC 相電壓' },
    { key: 'Vll_ca', name: 'AC 相電壓' },
    { key: 'Vln_a', name: 'A 相電壓' },
    { key: 'Vln_b', name: 'B 相電壓' },
    { key: 'Vln_c', name: 'C 相電壓' }
  ]

  // HV_METER
  const selectedHVmeter = selectedOptions.includes(HV_METER)
  if (
    selectedHVmeter &&
    firstData.detail.etc &&
    firstData.detail.etc[HV_METER]
  ) {
    const ws = workbook.addWorksheet(HV_METER)
    const fieldsColumn = meterKeys.map((k) => ({
      header: `${k.name} ${k.key}`,
      key: `${k.name} ${k.key}`,
      width: 15
    }))
    ws.columns = [...baseColumn, ...fieldsColumn]
  }

  // LV_METER
  const selectedLVmeter = selectedOptions.includes(LV_METER)
  if (
    selectedLVmeter &&
    firstData.detail.etc &&
    firstData.detail.etc[LV_METER]
  ) {
    const ws = workbook.addWorksheet(LV_METER)
    const fieldsColumn = meterKeys.map((k) => ({
      header: `${k.name} ${k.key}`,
      key: `${k.name} ${k.key}`,
      width: 15
    }))
    ws.columns = [...baseColumn, ...fieldsColumn]
  }

  // IRR
  const selectedIRRList = selectedOptions.filter((item) => item.match('IRR'))
  if (
    selectedIRRList?.length &&
    firstData.detail.IRR &&
    firstData.detail.IRR.length
  ) {
    selectedIRRList
      .sort((a, b) => a.split('_')[1] - b.split('_')[1])
      .forEach((irr) => {
        const ws = workbook.addWorksheet(irr)
        ws.columns = [
          ...baseColumn,
          {
            header: 'IRR_顯示值',
            key: 'value',
            width: 15
          }
        ]
      })
  }

  // PVTemp
  const selectedPVTemp = selectedOptions.includes(PVTEMP)
  if (
    selectedPVTemp &&
    firstData.detail &&
    typeof firstData.detail.PVTemp === 'number'
  ) {
    const ws = workbook.addWorksheet(PVTEMP)
    ws.columns = [
      ...baseColumn,
      {
        header: '模組溫度 temp',
        key: 'value',
        width: 15
      }
    ]
  }

  // ENVTemp
  const selectedENVTemp = selectedOptions.includes(ENVTEMP)
  if (
    selectedENVTemp &&
    firstData.detail &&
    typeof firstData.detail.ENVTemp === 'number'
  ) {
    const ws = workbook.addWorksheet(ENVTEMP)
    ws.columns = [
      ...baseColumn,
      {
        header: '環境溫度 temp',
        key: 'value',
        width: 15
      }
    ]
  }

  // Anemometer
  const selectedAnemometer = selectedOptions.includes(ANEMOMETER)
  if (
    selectedAnemometer &&
    firstData.detail &&
    typeof firstData.detail.Anemometer === 'number'
  ) {
    const ws = workbook.addWorksheet(ANEMOMETER)
    ws.columns = [
      ...baseColumn,
      {
        header: '風速計值',
        key: 'value',
        width: 15
      }
    ]
  }

  // INV
  const selectedINVList = selectedOptions.filter((item) => item.match('INV'))
  if (selectedINVList?.length) {
    const invRowKeys = [
      { key: 'pv_v', name: '串列電壓' },
      { key: 'pv_a', name: '串列電流' },
      { key: 'pv_p', name: '串列功率' },
      { key: 'temp', name: '變流器溫度' },
      { key: 'event', name: '事件' },
      { key: 'PF', name: '功率因數' },
      { key: 'Vrn', name: 'R 相電壓' },
      { key: 'Vsn', name: 'S 相電壓' },
      { key: 'Vtn', name: 'T 相電壓' },
      { key: 'Rc', name: 'R 相電流' },
      { key: 'Sc', name: 'S 相電流' },
      { key: 'Tc', name: 'T 相電流' },
      { key: 'Eff', name: '變流器轉換效率' },
      { key: 'State', name: '裝置狀態' },
      { key: 'acp', name: '交流功率' },
      { key: 'dcp', name: '直流功率' },
      { key: 'E_today', name: '今日發電量' },
      { key: 'AC_kWh', name: '累積發電量' },
      { key: 'freq', name: '頻率' },
      { key: 'Insulation', name: '絕緣阻抗' },
      { key: 'power_r', name: 'R 相發電' },
      { key: 'power_s', name: 'S 相發電' },
      { key: 'power_t', name: 'T 相發電' },
      { key: 'capacity', name: '裝置容量' }
    ]

    const detailColumns = baseColumn

    // 有的案場每台 INV pv、event 長度不同，先找出數量最多的那台，以那台數量為基準列出 row 數量
    const eventLength = Math.max(
      ...firstData.detail.event.map((item) => item.length)
    )
    const eventIndex = firstData.detail.event.findIndex(
      (item) => item.length === eventLength
    )

    const pvLength = Math.max(
      ...firstData.detail.pv_a.map((item) => item.length)
    )
    const pvIndex = firstData.detail.pv_v.findIndex(
      (item) => item.length === pvLength
    )

    invRowKeys.forEach((invKey) => {
      const index =
        invKey.key === 'event'
          ? eventIndex
          : invKey.key.split('_')[0] === 'pv'
            ? pvIndex
            : 0

      if (
        firstData.detail[invKey.key]?.[index] &&
        typeof firstData.detail[invKey.key]?.[index] === 'object' &&
        firstData.detail[invKey.key]?.[index].length > 0
      ) {
        firstData.detail[invKey.key][index].forEach((_, idx) => {
          detailColumns.push({
            header: `${invKey.name} ${invKey.key}${idx + 1}`,
            key: `${invKey.name} ${invKey.key}${idx + 1}`,
            width: 17
          })
        })
      } else {
        detailColumns.push({
          header: `${invKey.name} ${invKey.key}`,
          key: `${invKey.name} ${invKey.key}`,
          width: 17
        })
      }
    })

    selectedINVList
      .sort((a, b) => a.split('_')[1] - b.split('_')[1])
      .forEach((inv) => {
        const ws = workbook.addWorksheet(inv)
        ws.columns = detailColumns
      })
  }

  // add data to row
  data.forEach((doc, docIdx) => {
    const rowBase = {
      siteName: doc.factoryName
    }
    const hvMeterWorksheet = workbook.getWorksheet(HV_METER)
    const lvMeterWorksheet = workbook.getWorksheet(LV_METER)
    const pvTempWorksheet = workbook.getWorksheet(PVTEMP)
    const envTempWorksheet = workbook.getWorksheet(ENVTEMP)
    const anemometerWorksheet = workbook.getWorksheet(ANEMOMETER)
    const time = DateTime.fromISO(doc.timestamp.split('Z')[0]).toFormat(
      RAWDATA_TIME_FORMAT
    )

    if (hvMeterWorksheet) {
      const hvMeterData = meterKeys.reduce((accumulator, curr) => {
        accumulator[`${curr.name} ${curr.key}`] = doc.detail.etc[HV_METER][curr.key]
        return accumulator
      }, {})

      const etcRowsHV = {
        ...(docIdx === 0 ? rowBase : {}),
        device: docIdx === 0 ? '高壓電表' : '',
        time,
        ...hvMeterData
      }
      hvMeterWorksheet.addRow(etcRowsHV)
    }

    if (lvMeterWorksheet) {
      const lvMeterData = meterKeys.reduce((accumulator, curr) => {
        accumulator[`${curr.name} ${curr.key}`] = doc.detail.etc[LV_METER][curr.key]
        return accumulator
      }, {})

      const etcRowsLV = {
        ...(docIdx === 0 ? rowBase : {}),
        device: docIdx === 0 ? '低壓電表' : '',
        time,
        ...lvMeterData
      }
      lvMeterWorksheet.addRow(etcRowsLV)
    }

    if (pvTempWorksheet) {
      const pvTempRows = {
        ...(docIdx === 0 ? rowBase : {}),
        device: docIdx === 0 ? '模組溫度' : '',
        time,
        value: doc.detail.PVTemp ? doc.detail.PVTemp : 0
      }
      pvTempWorksheet.addRow(pvTempRows)
    }

    if (envTempWorksheet) {
      const envTempRows = {
        ...(docIdx === 0 ? rowBase : {}),
        device: docIdx === 0 ? '環境溫度' : '',
        time,
        value: doc.detail.ENVTemp ? doc.detail.ENVTemp : 0
      }
      envTempWorksheet.addRow(envTempRows)
    }

    if (anemometerWorksheet) {
      const anemometerRows = {
        ...(docIdx === 0 ? rowBase : {}),
        device: docIdx === 0 ? '風速計' : '',
        time,
        value: doc.detail.Anemometer ? doc.detail.Anemometer : 0
      }
      anemometerWorksheet.addRow(anemometerRows)
    }

    // IRR row
    if (selectedIRRList?.length) {
      doc.detail.IRR.forEach((irr, irrIdx) => {
        const foundIRR = selectedIRRList.find(
          (irrItem) => irrItem.split('_')[1] - 1 === irrIdx
        )
        if (foundIRR) {
          const irrRows = {
            ...(docIdx === 0 ? rowBase : {}),
            time,
            device: docIdx === 0 ? '日照計 ' + foundIRR : '',
            value: irr
          }
          workbook.getWorksheet(foundIRR).addRow(irrRows)
        }
      })
    }

    // INV row
    if (selectedINVList?.length) {
      for (let i = 0; i < invLength; i++) {
        const foundINV = selectedINVList.find(
          (invItem) => invItem.split('_')[1] - 1 === i
        )
        if (foundINV) {
          const ws = workbook.getWorksheet(foundINV)
          const row = {
            ...(docIdx === 0 ? rowBase : {}),
            time,
            device: docIdx === 0 ? '變流器 ' + foundINV : '',
            '功率因數 PF': doc.detail.PF[i],
            'R 相電壓 Vrn': doc.detail.Vrn[i],
            'S 相電壓 Vsn': doc.detail.Vsn[i],
            'T 相電壓 Vtn': doc.detail.Vtn[i],
            'R 相電流 Rc': doc.detail.Rc[i],
            'S 相電流 Sc': doc.detail.Sc[i],
            'T 相電流 Tc': doc.detail.Tc[i],
            '頻率 freq': doc.detail.freq[i],
            '變流器轉換效率 Eff': doc.detail.Eff[i],
            '裝置狀態 State': doc.detail.State[i],
            '交流功率 acp': doc.detail.acp[i],
            '直流功率 dcp': doc.detail.dcp[i],
            '今日發電量 E_today': doc.detail.E_today[i],
            '累積發電量 AC_kWh': doc.detail.AC_kWh[i],
            '絕緣阻抗 Insulation': doc.detail.Insulation?.[i] ?? null,
            'R 相發電 power_r': doc.detail.power_r[i],
            'S 相發電 power_s': doc.detail.power_s[i],
            'T 相發電 power_t': doc.detail.power_t[i],
            '裝置容量 capacity': doc.detail.capacity[i]
          }

          doc.detail.pv_v[i].forEach((pv, pvIdx) => {
            row[`串列電壓 pv_v${pvIdx + 1}`] = pv
          })
          doc.detail.pv_a[i].forEach((pv, pvIdx) => {
            row[`串列電流 pv_a${pvIdx + 1}`] = pv
          })
          doc.detail.pv_p[i].forEach((pv, pvIdx) => {
            row[`串列功率 pv_p${pvIdx + 1}`] = pv
          })
          doc.detail.temp[i].forEach((t, tIdx) => {
            row[`變流器溫度 temp${tIdx + 1}`] = t
          })
          doc.detail.event[i].forEach((t, tIdx) => {
            row[`事件 event${tIdx + 1}`] = t
          })

          ws.addRow(row)
        }
      }
    }
  })

  // if no data
  if (!workbook.worksheets.length) return false

  // 資料下載
  const attachment = await workbook.xlsx.writeBuffer()
  const url = window.URL.createObjectURL(new Blob([attachment]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${factoryName}_${date}.xlsx`)
  link.click()
  return true
}

export { rawDataDownload }
